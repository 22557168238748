var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticketMain" },
    [
      _c(
        "div",
        { staticClass: "ticketLeft" },
        [
          _c("head-layout", { attrs: { "head-title": "作业票流程" } }),
          _c(
            "div",
            { staticClass: "ticketStep" },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    direction: "vertical",
                    active: _vm.ticketStep,
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", {
                    attrs: { title: "申请" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("draft")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "安全交底" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("disclose")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "安全监管" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("supervise")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "验收确认" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("confirm")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "完成" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("confirm")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "ticketRight" }, [
        _c(
          "div",
          { staticClass: "ticketHead" },
          [
            _c("head-layout", {
              attrs: {
                "head-title": "作业票基本信息",
                "head-btn-options": _vm.headBtnOptions,
              },
              on: {
                "head-save": function ($event) {
                  return _vm.headSave("saveData")
                },
                "head-refer": function ($event) {
                  return _vm.headSave("processSubmission")
                },
                "head-cancel": _vm.headCancel,
              },
            }),
            _c(
              "el-button",
              { staticClass: "ticketHeadBtn", attrs: { type: "primary" } },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.ticketForm.jobTicketName) +
                    "\n        "
                ),
              ]
            ),
            _c(
              "el-form",
              {
                staticClass: "ticketHeadCode",
                attrs: { model: _vm.dataForm, "label-width": "100px" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "作业票编号:", prop: "ticketCode" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: "请输入作业票编号",
                        disabled: true,
                      },
                      model: {
                        value: _vm.dataForm.ticketCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "ticketCode", $$v)
                        },
                        expression: "dataForm.ticketCode",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "ticketContent" }, [
          _c(
            "div",
            { staticClass: "ticketForm", attrs: { id: "draft" } },
            [
              _c(
                "el-form",
                {
                  ref: "formLayout",
                  attrs: {
                    model: _vm.dataForm,
                    rules: _vm.rules,
                    disabled: ["view", "flow", "sign", "workflow"].includes(
                      _vm.type
                    ),
                    "label-width": "140px",
                  },
                },
                [
                  _c("offlineImg", {
                    ref: "offlineImg",
                    attrs: {
                      disabled: _vm.businessStatus == "PREPARE" ? true : false,
                    },
                  }),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "安全交底人:",
                        prop: "securityDisclosureName",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请选择安全交底人",
                            readonly: "",
                            maxlength: "255",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.dataForm.securityDisclosureName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataForm,
                                "securityDisclosureName",
                                $$v
                              )
                            },
                            expression: "dataForm.securityDisclosureName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.openUser("securityDisclosureName")
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticketGas" },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "安全监管人",
                  "head-btn-options": _vm.superviseHeadBtn,
                },
                on: { "head-add": _vm.addSupervise },
              }),
              _c(
                "div",
                { staticClass: "gasTable" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.safetySupervisorList,
                        border: "",
                        size: "mini",
                        height: 300,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "conservatorName",
                          align: "center",
                          label: "监管人名称",
                        },
                      }),
                      ["edit", "add"].includes(_vm.type)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteSupervise(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1984254984
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          ["DISCLOSED", "SUPERVISE", "CHECKACCEPT", "FINISHED"].includes(
            _vm.businessStatus
          ) &&
          ["supervise", "safeConfessor", "respUser", "drafter"].includes(
            _vm.characters
          )
            ? _c(
                "div",
                { staticClass: "ticketGas", attrs: { id: "disclose" } },
                [
                  _c("head-layout", {
                    attrs: { "head-title": "安全交底" },
                    on: { "head-add": _vm.addDisclose },
                  }),
                  _c(
                    "div",
                    { staticClass: "gasTable" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "discloseForm",
                          attrs: {
                            model: _vm.discloseForm,
                            rules: _vm.discloseRules,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "上传附件", prop: "documentUrl" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action:
                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                    disabled:
                                      _vm.type == "view" &&
                                      _vm.businessStatus != "DISCLOSED",
                                    headers: _vm.headers,
                                    "on-success": _vm.discloseSuc,
                                    "on-remove": _vm.discloseRem,
                                    "on-preview": _vm.downloadFile,
                                    multiple: "",
                                    limit: 3,
                                    "file-list": _vm.documentUrl,
                                  },
                                },
                                [
                                  _vm.type != "view" &&
                                  _vm.businessStatus == "DISCLOSED"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("点击上传")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("securityDisclosure", {
                        ref: "securityDisclosure",
                        attrs: {
                          securityType: _vm.$route.query.type,
                          businessStatus: _vm.businessStatus,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          ["SUPERVISE", "CHECKACCEPT", "FINISHED"].includes(
            _vm.businessStatus
          ) &&
          ["supervise", "safeConfessor", "respUser", "drafter"].includes(
            _vm.characters
          )
            ? _c(
                "div",
                { staticClass: "ticketGas", attrs: { id: "supervise" } },
                [
                  _c("superviseImg", {
                    ref: "superviseImg",
                    attrs: {
                      disabled:
                        _vm.$route.query.doned != "1" &&
                        _vm.type == "flow" &&
                        _vm.characters == "supervise",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          ["CHECKACCEPT", "FINISHED"].includes(_vm.businessStatus) &&
          ["supervise", "safeConfessor", "respUser", "drafter"].includes(
            _vm.characters
          )
            ? _c(
                "div",
                { staticClass: "ticketGas", attrs: { id: "confirm" } },
                [
                  _c("finishWorkImg", {
                    ref: "finishWorkImg",
                    attrs: {
                      disabled:
                        _vm.$route.query.doned != "1" &&
                        _vm.type == "flow" &&
                        _vm.characters == "respUser",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("jobTicketImage", {
        ref: "jobTicketImage",
        attrs: { title: "在线预览" },
      }),
      _vm.signatureShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "电子签名",
                visible: _vm.signatureShow,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.signatureShow = $event
                },
              },
            },
            [_c("signature", { on: { getSignatureImg: _vm.getSignatureImg } })],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "部门选择",
            visible: _vm.deptBelowShow,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptBelowShow = $event
            },
          },
        },
        [
          _c("currentDepartmentBelow", {
            on: { getDeptBelow: _vm.getDeptBelow },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userShow,
            top: "9vh",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userShow = $event
            },
          },
        },
        [
          _vm.userShow
            ? _c("ProjectSelectUser", {
                attrs: {
                  isDel: true,
                  multiple: "",
                  checkedData: _vm.checkedUserData,
                },
                on: { "select-data": _vm.getUser, closeDia: _vm.closeUser },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userListShow,
            top: "9vh",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userListShow = $event
            },
          },
        },
        [
          _vm.userListShow
            ? _c("UserDeptMultipleDialog", {
                ref: "userDeptMultipleDialog",
                on: {
                  "select-all-data": _vm.getUserList,
                  closeDia: _vm.closeDia,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.jobTicketDialogShow
        ? _c(
            "common-dialog",
            {
              attrs: {
                dialogTitle: "作业票清单",
                width: "60%",
                showConfirmBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.jobTicketDialogShow = false
                },
              },
            },
            [
              _c("jobTicketDialog", {
                ref: "jobTicketDialog",
                attrs: { ticketCode: _vm.dataForm.ticketCode },
                on: { submitData: _vm.submitData },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("process-user-dialog", {
        ref: "processUser",
        on: {
          getUserSelection: _vm.handleUserSelection,
          closeProcess: _vm.closeProcess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }